import React from "react";
import { Form, Input, Button, Breadcrumb } from "antd";
import { Link } from "gatsby";
import { PlusOutlined, HomeOutlined } from "@ant-design/icons";
import Container from "../../components/Container";
import Card from "../../components/Card";
import useTranslation from "../../lang/useTranslation";
import useSubmitState from "../../components/useSubmitState";
import { pY } from "../../components/spacingShorthands";
import { useNavigate } from "../../components/reach-router";
import { trackNProgress } from "../../components/nProgress";
import { callApi } from "../../components/api";

export default () => {
  const navigate = useNavigate();
  const [isSubmitting, onSubmit] = useSubmitState(async ({ name }) => {
    const { _id } = await trackNProgress(
      callApi({ path: "brands/create", method: "POST", params: { name } })
    );
    navigate(`brands/${_id}`);
  });
  return (
    <Container
      css={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div css={pY(16)}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="..">
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>New brand</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Container
        size="sm"
        css={{
          ...pY(16),
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Card title="New brand">
          <Form
            onFinish={onSubmit}
            validateTrigger="onSubmit"
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ sm: { span: 16 } }}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, message: useTranslation("required") },
                { type: "string", max: 100, message: "Too long" },
              ]}
            >
              <Input autoFocus disabled={isSubmitting} />
            </Form.Item>
            <Form.Item
              wrapperCol={{ sm: { offset: 4 } }}
              css={{ marginBottom: 0 }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                icon={<PlusOutlined />}
              >
                Create
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Container>
    </Container>
  );
};
