import React, { useState } from "react";
import { Button } from "antd";
import page from "../components/page";
import NewProduct from "./pages/NewProduct";
import EditProduct from "./pages/EditProduct";
import { Router, Redirect } from "../components/reach-router";
import { Content404 } from "../pages/404";
import NewBrand from "./pages/NewBrand";
import EditBrand from "./pages/EditBrand";
import Brands from "./pages/Brands";
import { isAuthed, callApi, deleteToken } from "../components/api";
import { trackNProgress } from "../components/nProgress";

const HeaderMenu = () => {
  const [loading, setLoading] = useState(false);
  return (
    <Button
      loading={loading}
      type="ghost"
      css={{ color: "white" }}
      onClick={async () => {
        setLoading(true);
        await trackNProgress(callApi({ path: "users/logOut", method: "POST" }));
        deleteToken();
        window.location = "/";
      }}
    >
      Log out
    </Button>
  );
};

export default page(
  () =>
    isAuthed() ? (
      <Router
        css={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
        basepath="/app"
      >
        <Redirect noThrow from="/" to="brands" />
        <Brands path="/brands" />
        <NewBrand path="/new-brand" />
        <EditBrand path="/brands/:brand" />
        <NewProduct path="/brands/:brand/new-product" />
        <EditProduct path="/products/:product" />
        <Content404 default />
      </Router>
    ) : (
      <Redirect noThrow to="/login" />
    ),
  { logoLink: "/app", HeaderMenu }
);
