import React from "react";
import {
  Divider,
  Form,
  Input,
  Button,
  Select,
  Switch,
  Descriptions,
  Breadcrumb,
  message,
  Modal,
} from "antd";
import { CheckOutlined, HomeOutlined } from "@ant-design/icons";
import QrCode from "qrcode.react";
import Container from "../../components/Container";
import { Title, Text, Paragraph } from "../../components/typography";
import { useParams, Link, useNavigate } from "../../components/reach-router";
import { pY } from "../../components/spacingShorthands";
import useSubmitState from "../../components/useSubmitState";
import useTranslation from "../../lang/useTranslation";
import { darkGreen, green } from "../../theme/colors";
import logoQrCode from "../../images/logo-qrcode.png";
import {
  useRemoteEditor,
  RemoteEditorProvider,
} from "../../components/remoteEditor";
import { trackNProgress } from "../../components/nProgress";
import { callApi } from "../../components/api";
import LciForm from "../../components/LciForm";
import ClimateFacts from "../../components/ClimateFacts";

const Details = () => {
  const [{ _id, ...product }, updateProduct] = useRemoteEditor();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isSubmitting, onSubmit] = useSubmitState(async (details) => {
    if (details.live && !product.brand.stripeCustomer) {
      Modal.confirm({
        title: "Upgrade needed",
        content:
          "You need to upgrade your account in your brand's settings to turn on live mode.",
        okText: "Upgrade",
        onOk: () => navigate(`/app/brands/${product.brand._id}`),
        onCancel: () => {
          form.setFieldsValue(product);
          message.error("Update cancelled");
        },
      });
      return false;
    }
    await trackNProgress(
      callApi({
        path: "products/update",
        params: { product: _id, details },
        method: "POST",
      })
    );
    updateProduct((b) => ({ ...b, ...details }));
    message.success("Updated!");
    return false;
  });
  const kgCo2e = product.facts
    ? product.facts.manufacturing +
      product.facts.rawMaterials +
      product.facts.packaging +
      product.facts.transportation
    : 0;
  return (
    <Form
      form={form}
      initialValues={{ project: "pur", ...product }}
      onFinish={onSubmit}
      validateTrigger="onSubmit"
      layout="horizontal"
      labelCol={{ span: 4 }}
      wrapperCol={{ sm: { span: 16 } }}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          { required: true, message: useTranslation("required") },
          { type: "string", max: 100, message: "Too long" },
        ]}
      >
        <Input disabled={isSubmitting} />
      </Form.Item>
      <Form.Item label="Project funded">
        <Form.Item name="project">
          <Select disabled={isSubmitting}>
            <Select.Option value="pur">PUR Hexagone</Select.Option>
          </Select>
        </Form.Item>
        <Paragraph>{useTranslation("purHexagoneDescription")}</Paragraph>
      </Form.Item>
      <Form.Item label="Live">
        <Form.Item name="live" valuePropName="checked">
          <Switch loading={isSubmitting} />
        </Form.Item>
        <Paragraph>
          <div>
            When live mode is enabled, for every product sold, Contreeb will
            collect
          </div>
          <Text strong>
            {((kgCo2e * product.brand.tonCo2Price) / 1000).toFixed(2)}€ for this
            project + 20% transaction fee
            <span css={{ color: green }}>
              <> = </>
              {((1.2 * kgCo2e * product.brand.tonCo2Price) / 1000).toFixed(2)}€
            </span>
          </Text>
        </Paragraph>
      </Form.Item>
      <Form.Item wrapperCol={{ sm: { offset: 4 } }} css={{ marginBottom: 0 }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
          icon={<CheckOutlined />}
        >
          Update
        </Button>
      </Form.Item>
    </Form>
  );
};

const ClimateFootprint = () => {
  const [product, updateProduct] = useRemoteEditor();

  return (
    <>
      <LciForm
        initialValues={product.lci}
        onSubmit={async (lci) => {
          const { facts } = await trackNProgress(
            callApi({
              method: "POST",
              path: "products/computeFootprint",
              params: { lci, product: product._id },
            })
          );
          updateProduct((p) => ({ ...p, lci, facts }));
          return false;
        }}
      />
      {product.facts && <ClimateFacts facts={product.facts} />}
    </>
  );
};

const Report = () => {
  const [{ _id }] = useRemoteEditor();
  return (
    <Descriptions bordered column={1}>
      <Descriptions.Item
        label={
          <>
            <Text strong>Link</Text>
            <br />
            <Text type="secondary">for your website</Text>
          </>
        }
      >
        <Text copyable={{ text: `${window.origin}/pr/${_id}` }}>
          <a href={`/p/${_id}`} target="_blank" rel="noreferrer">
            {window.origin}/p/{_id}
          </a>
        </Text>
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <>
            <Text strong>Barcode</Text>
            <br />
            <Text type="secondary">for your physical tags</Text>
          </>
        }
      >
        <QrCode
          css={{
            borderRadius: 4,
            display: "block",
          }}
          imageSettings={{
            src: logoQrCode,
            height: 24,
            width: 24,
            excavate: true,
          }}
          level="Q"
          includeMargin
          bgColor={darkGreen}
          fgColor="white"
          value={`${window.origin}/p/${_id}`}
        />
      </Descriptions.Item>
    </Descriptions>
  );
};

const PageTitle = () => {
  const [{ name, brand }] = useRemoteEditor();
  return (
    <>
      <div css={pY(16)}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/app">
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/app/brands/${brand._id}`}>{brand.name}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Title>{name}</Title>
    </>
  );
};

export default () => (
  <RemoteEditorProvider path="products/details" params={useParams()}>
    <Container css={pY(32)}>
      <PageTitle />
      <Divider css={pY(16)} orientation="left">
        Climate footprint
      </Divider>
      <ClimateFootprint />
      <Divider css={pY(16)} orientation="left">
        Details
      </Divider>
      <Details />
      <Divider css={pY(16)} orientation="left">
        Carbon Facts
      </Divider>
      <Report />
    </Container>
  </RemoteEditorProvider>
);
