import React, { useState } from "react";
import {
  Divider,
  Form,
  Input,
  Button,
  Slider,
  List,
  Row,
  Col,
  Breadcrumb,
  message,
  Descriptions,
  Modal,
} from "antd";
import { Link as GbLink } from "gatsby";
import {
  CheckOutlined,
  RocketOutlined,
  PlusOutlined,
  UserAddOutlined,
  HomeOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { loadStripe } from "@stripe/stripe-js";
import produce from "immer";
import Container from "../../components/Container";
import { Title, Text, Link, Paragraph } from "../../components/typography";
import { useParams } from "../../components/reach-router";
import { pY } from "../../components/spacingShorthands";
import useSubmitState from "../../components/useSubmitState";
import useTranslation from "../../lang/useTranslation";
import { callApi } from "../../components/api";
import { trackNProgress } from "../../components/nProgress";
import InlineForm from "../../components/InlineForm";
import {
  RemoteEditorProvider,
  useRemoteEditor,
} from "../../components/remoteEditor";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);

const Details = () => {
  const [{ _id, ...brand }, updateBrand] = useRemoteEditor();
  const [isSubmitting, onSubmit] = useSubmitState(async (details) => {
    await trackNProgress(
      callApi({
        path: "brands/update",
        params: { brand: _id, details },
        method: "POST",
      })
    );
    updateBrand((b) => ({ ...b, ...details }));
    message.success("Updated!");
    return false;
  });
  return (
    <Form
      initialValues={brand}
      onFinish={onSubmit}
      validateTrigger="onSubmit"
      layout="horizontal"
      labelCol={{ span: 4 }}
      wrapperCol={{ sm: { span: 16 } }}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          { required: true, message: useTranslation("required") },
          { type: "string", max: 100, message: "Too long" },
        ]}
      >
        <Input disabled={isSubmitting} />
      </Form.Item>
      <Form.Item
        label={
          <span>
            Price of CO<sub>2</sub>
          </span>
        }
      >
        <Form.Item name="tonCo2Price">
          <Slider
            disabled={isSubmitting}
            min={10}
            max={400}
            step={1}
            tipFormatter={(v) => `${v}€`}
            marks={{ 45: "45€", 100: "100€", 370: "370€" }}
          />
        </Form.Item>
        <div>
          <Text strong>45€ </Text> (44.60€) is the price evaluated by French
          authorities since 2018
        </div>
        <div>
          <Text strong>100€</Text> is the price forecasted by French authorities
          for 2030
        </div>
        <div>
          <Text strong>370€ </Text> (~ 417$ in July 2020) is the price according
          to{" "}
          <Link
            href="https://www.nature.com/articles/s41558-018-0282-y"
            target="_blank"
            rel="noreferrer"
          >
            this article
          </Link>{" "}
          in Nature, one of the world&apos;s most renowned scientific journals.
        </div>
      </Form.Item>
      <Form.Item wrapperCol={{ sm: { offset: 4 } }} css={{ marginBottom: 0 }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
          icon={<CheckOutlined />}
        >
          Update
        </Button>
      </Form.Item>
    </Form>
  );
};

const Plan = () => {
  const [{ _id: brand, stripeCustomer }] = useRemoteEditor();
  const t = useTranslation();
  const current = t(stripeCustomer ? "proPlan" : "freePlan");
  const next = stripeCustomer
    ? { name: t("enterprisePlanName"), features: [t("biggerNeeds")] }
    : t("proPlan");
  const [subscribing, setSubscribing] = useState(false);
  return (
    <div>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Current plan">
          <Title level={4} css={{ fontSize: "1em !important" }}>
            {current.name}
          </Title>
          <Text type="secondary" css={{ display: "block" }}>
            {current.features.join(" / ")}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Upgrade">
          <Title level={4} css={{ fontSize: "1em !important" }}>
            {next.name}
          </Title>
          <Paragraph>
            <Text type="secondary">{next.features.join(" / ")}</Text>
          </Paragraph>
          {stripeCustomer ? (
            <a
              href="mailto:hello@contreeb.com"
              target="_blank"
              rel="noreferrer"
            >
              <Button type="primary" icon={<MailOutlined />}>
                {t("letsTalk")}
              </Button>
            </a>
          ) : (
            <Button
              loading={subscribing}
              type="primary"
              icon={<RocketOutlined />}
              onClick={() =>
                trackNProgress(async (incrementProgress) => {
                  setSubscribing(true);
                  const stripe = await stripePromise;
                  incrementProgress();
                  const { sessionId } = await callApi({
                    path: "brands/createCheckoutSession",
                    params: { brand },
                    method: "POST",
                  });
                  incrementProgress();
                  const { error } = await stripe.redirectToCheckout({
                    sessionId,
                  });
                  if (error) {
                    setSubscribing(false);
                    message.error(error.message);
                  }
                })
              }
            >
              Upgrade
            </Button>
          )}
        </Descriptions.Item>
      </Descriptions>
      <div css={{ marginTop: 8, textAlign: "center" }}>
        Of course you can cancel at any moment, for any reason. Just{" "}
        <a href="mailto:hello@contreeb.com" target="_blank" rel="noreferrer">
          tell us
        </a>{" "}
        and we&apos;ll stop charging you immediately.
      </div>
    </div>
  );
};

const Users = () => {
  const [form] = Form.useForm();
  const [
    { users, _id: brandId, stripeCustomer },
    updateBrand,
  ] = useRemoteEditor();
  const [isSubmitting, onSubmit] = useSubmitState(async ({ email }) => {
    if (!stripeCustomer || users.length >= 3) {
      Modal.warn({
        title: "Upgrade needed",
        content: "You need to upgrade your account to add more team members.",
      });
      return false;
    }
    const { _id: newUserId } = await trackNProgress(
      callApi({
        path: "brands/users/invite",
        method: "POST",
        params: { email, brand: brandId },
      })
    );
    updateBrand(
      produce((draft) => {
        if (!draft.users.some(({ _id }) => _id === newUserId))
          draft.users.push({ email, _id: newUserId });
      })
    );
    form.resetFields();
    return false;
  });

  const userRemover = (user) => async () => {
    await trackNProgress(
      callApi({
        path: "brands/users/remove",
        method: "POST",
        params: { user, brand: brandId },
      })
    );
    updateBrand(({ users: u, ...brand }) => ({
      users: u.filter(({ _id }) => _id !== user),
      ...brand,
    }));
  };

  return (
    <Row>
      <Col span={24} sm={{ span: 16, offset: 4 }}>
        <List
          footer={
            <InlineForm onFinish={onSubmit} form={form}>
              <Form.Item
                name="email"
                css={{ flex: "1 !important" }}
                rules={[
                  { required: true, message: useTranslation("required") },
                  { type: "email", message: useTranslation("invalid") },
                ]}
              >
                <Input placeholder="Email" disabled={isSubmitting} />
              </Form.Item>
              <Button
                loading={isSubmitting}
                type="primary"
                htmlType="sumbit"
                icon={<UserAddOutlined />}
              >
                Invite
              </Button>
            </InlineForm>
          }
          bordered
          dataSource={users}
          renderItem={({ _id, email, isMe }) => (
            <List.Item
              key={_id}
              actions={
                isMe
                  ? []
                  : [
                      <Text type="danger" onClick={userRemover(_id)}>
                        Remove
                      </Text>,
                    ]
              }
            >
              {email}
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

const Products = () => {
  const [{ products, stripeCustomer, name: brandName }] = useRemoteEditor();
  return (
    <Row>
      <Col span={24} sm={{ span: 16, offset: 4 }}>
        <List
          bordered
          header={
            <div css={{ textAlign: "center" }}>
              <GbLink
                onClick={(e) => {
                  if (
                    (!stripeCustomer && products.length >= 1) ||
                    products.length >= 10
                  ) {
                    Modal.warn({
                      title: "Upgrade needed",
                      content:
                        "You need to upgrade your account to add more products.",
                    });
                    e.preventDefault();
                  }
                }}
                to="new-product"
                state={{ brandName }}
              >
                <Button type="primary" icon={<PlusOutlined />}>
                  Add product
                </Button>
              </GbLink>
            </div>
          }
          dataSource={products}
          renderItem={({ _id, name }) => (
            <List.Item key={_id}>
              <GbLink to={`/app/products/${_id}`}>{name}</GbLink>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

const PageTitle = () => {
  const [{ name }] = useRemoteEditor();
  return (
    <>
      <div css={pY(16)}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <GbLink to="..">
              <HomeOutlined />
            </GbLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Title>{name}</Title>
    </>
  );
};

export default () => (
  <RemoteEditorProvider path="brands/details" params={useParams()}>
    <Container css={pY(32)}>
      <PageTitle />
      <Divider css={pY(16)} orientation="left">
        Details
      </Divider>
      <Details />
      <Divider css={pY(16)} orientation="left">
        Plan
      </Divider>
      <Plan />
      <Divider css={pY(16)} orientation="left">
        Users
      </Divider>
      <Users />
      <Divider css={pY(16)} orientation="left">
        Products
      </Divider>
      <Products />
      <div css={pY(16)} />
    </Container>
  </RemoteEditorProvider>
);
