import React from "react";
import { Button, List } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "gatsby";
import Container from "../../components/Container";
import { pY } from "../../components/spacingShorthands";
import { Redirect } from "../../components/reach-router";
import { Text } from "../../components/typography";
import { useApi } from "../../components/api";
import { useNProgress } from "../../components/nProgress";

export default () => {
  const result = useNProgress(useApi({ path: "brands/mine" }));
  if (result && !result.brands.length)
    return <Redirect noThrow to="../new-brand" />;
  return (
    <Container
      size="sm"
      css={{
        ...pY(32),
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <List
        bordered
        loading={!result}
        size="large"
        header={<Text strong>Select a brand...</Text>}
        footer={
          <div css={{ textAlign: "center" }}>
            <Link to="../new-brand">
              <Button type="primary" icon={<PlusOutlined />}>
                Add brand
              </Button>
            </Link>
          </div>
        }
        dataSource={result && result.brands}
        renderItem={({ _id, name }) => (
          <List.Item key={_id}>
            <Link to={_id}>{name}</Link>
          </List.Item>
        )}
      />
    </Container>
  );
};
