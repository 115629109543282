import React from "react";
import { Form, Input, Button, Breadcrumb } from "antd";
import { PlusOutlined, HomeOutlined } from "@ant-design/icons";
import { Link } from "gatsby";
import Container from "../../components/Container";
import Card from "../../components/Card";
import useTranslation from "../../lang/useTranslation";
import useSubmitState from "../../components/useSubmitState";
import { pY } from "../../components/spacingShorthands";
import {
  useNavigate,
  useParams,
  useLocation,
} from "../../components/reach-router";
import { callApi, useApi } from "../../components/api";
import { trackNProgress, useNProgress } from "../../components/nProgress";
import { Content404 } from "../../pages/404";

export default () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const stBrandName = state && state.brandName;
  const params = useParams();
  const brand = useNProgress(
    useApi(!stBrandName && { path: "brands/details", params })
  );
  const t = useTranslation();
  const [isSubmitting, onSubmit] = useSubmitState(async ({ name }) => {
    const { _id } = await trackNProgress(
      callApi({
        path: "products/create",
        method: "POST",
        params: { ...params, name },
      })
    );
    navigate(`/app/products/${_id}`);
  });

  if (!brand) return null;
  if (brand && brand.error) return <Content404 />;

  const brandName = stBrandName || (brand && brand.name);
  return (
    <Container
      css={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div css={pY(16)}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="../..">
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="..">{brandName}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>New product</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Container
        size="sm"
        css={{
          ...pY(16),
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Card title="New product">
          <Form
            onFinish={onSubmit}
            validateTrigger="onSubmit"
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ sm: { span: 16 } }}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, message: t("required") },
                { type: "string", max: 100, message: "Too long" },
              ]}
            >
              <Input autoFocus disabled={isSubmitting} />
            </Form.Item>
            <Form.Item
              wrapperCol={{ sm: { offset: 4 } }}
              css={{ marginBottom: 0 }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                icon={<PlusOutlined />}
              >
                Create
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Container>
    </Container>
  );
};
